<template>
  <div class="panel">
    <div class="panel__menu">
      <Btn @click="$router.push('/app')">Выход</Btn>    
    </div>
    <div class="panel__content">В разработке</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Btn from '../components/buttons/Btn.vue';
export default {
  components: { Btn },
  computed: {
    ...mapState(['url'])
  }
}
</script>

<style lang="sass" scoped>
.panel
  display: flex
  min-height: 100vh
  &__menu
    background: lighten(colors.$blue, 30)
    width: 250px
    max-width: 35%
    padding-top: 20px
    button
      width: 100%
      display: flex
      align-items: center
      justify-content: center
      min-height: 45px
      font-size: 24px
      border-radius: 0
  &__content
    padding: 20px
    overflow-y: auto

</style>