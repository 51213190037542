<template>
  <select class="select" v-model="currentFolder" >
    <option value=""></option>
    <option v-for="folder in FolderList" :value="folder.name" :key="folder.name">{{folder.name}}</option>
  </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['url','FolderList','folder']),
    currentFolder: {
      get(){
        return this.folder? this.folder.name : null
      },
      set(name){
        if (name) {
          this.$store.dispatch('setFolderByName', name)
        } else {
          this.$store.commit('setProject', null)
          this.$store.commit('setFolder', null)
          //this.$store.commit('setReport', null)
        }
      }
    }
  }
}
</script>

<style lang="sass">
.select
  min-width: 200px
  font-size: 18px
  padding: 2px 5px
  border-radius: 5px
</style>