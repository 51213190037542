<template>
  <div class="tablezone">
    <div class="uptable-controls tablezone__controls">
      <slot />
      <Btn :small="true" v-if="(datecount)" class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
    </div>
    <div style="overflow: auto; width: 100%;">
      <table :data-cols-width="getColWidth" class="table" ref="table">
        <tr>
          <td data-a-h="center" data-a-v="middle"></td>
          <td data-a-h="center" data-a-v="middle" v-for="date in dates" :key="date"><b>{{ date.split('/')[0] + '.' + date.split('/')[1] }}</b></td>
        </tr>
        <tr v-for="folder in folders" :key="folder">
          <td data-a-v="middle" class="link"> {{ folder }}</td>
          <td data-a-h="center" data-a-v="middle" v-for="v,i in project.history.data[folder]" :key="i">{{ v }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import TableToExcel from "@linways/table-to-excel"
import { mapState } from "vuex";
import Btn from '../buttons/Btn.vue';

export default {
  components: {
    Btn
  },
  computed: {
    ...mapState(['project']),
    url(){
      return this.project.url.trim().replace(/\/$/, '')
    },
    dates(){
      return this.project.history.dates
    },
    folders(){
      const folders = Object.keys(this.project.history.data)
      const sums = {}
      for (let folder of folders)
        sums[folder] = this.project.history.data[folder].reduce((a,b) => a + b, 0)
      folders.sort((a,b) => sums[b] - sums[a])
      return folders
    },
    datecount() {
      return this.dates.length
    },
    getColWidth() {
      let str = "40" + this.dates.map(() => ",10").join("")
      return str;
    },
  },
  methods: {
    save() {
      TableToExcel.convert(this.$refs.table, {
        name: "table.xlsx",
        sheet: {
          name: "Данные"
        }
      });
    },
  },
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$yandex: #ffc107
$ydark: #ad8305
$google: #4680FF
$gdark: #2d54a8
$success: #198754
$dgreen: darken(#198754, .2)
$primary: #0d6efd
.modeswitcher
  margin-bottom: 10px
  &__title
    font-size: 18px
    margin-bottom: 5px
  &, label
    display: flex
    align-items: center
  gap: 10px
  input
    display: block
    margin-right: 5px

.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
    margin-bottom: 20px
.table  
  display: block
  min-width: fit-content
  font-size: 14px
  margin-bottom: 30px

  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  tr
    font-weight: 500
    white-space: nowrap
  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      &:hover 
        overflow: visible
        padding: 0
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.6)
    &.found
      background: rgba(100, 200, 100, .4)
      &:hover
        background: rgba($success, .2)
      &.halffound
        background: $dgreen
        &:hover
          background: lighten($dgreen, .40)
    &.yandex
      background: rgba($yandex, .3)
      &:hover
        background: rgba($yandex, .15)
      &.halffound
        backgound: $ydark
        &:hover
          background: lighten($ydark, .40)
    &.google
      background: rgba($google, .3)
      &:hover
        background: rgba($google, .15)
      &.halffound
        backgound: $gdark
        &:hover
          background: lighten($gdark, .40)        
    &.mention
      background: rgba($yandex, .18)
      &:hover
        background: rgba($yandex, .1)
    &.notfound
      background: rgba($danger, .2)
      &:hover
        background: rgba($danger, .1)
    &.broken
      background: rgba(black, .8)
      &:hover
        background: rgba(black, .6)
    &.unknown
      background: rgba(black, .2)
      &:hover
        background: rgba(black, .1)

</style>