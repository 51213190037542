<template>
  <div class="balance">
    <div class="balance__string">XMLstock (yandex): <b>{{ybalance}}</b></div>
    <div class="balance__string">XMLriver (google): <b>{{gbalance}}</b></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: ()=>({
    ybalance: 'Неизвестен',
    gbalance: 'Неизвестен',
  }),
  async mounted() {
    let data, ybalance, gbalance
    try {
      data = await fetch(this.url + `balance`, {
        credentials: 'include',
      })
        .then(res=>res.json())
      ybalance = data.ybalance
      gbalance = data.gbalance
    } catch(err) {
      console.error(err)
      ybalance='Ошибка'
      gbalance='Ошибка'
    }
    this.ybalance = ybalance
    this.gbalance = gbalance
  },
  computed: {
    ...mapState(['url'])
  }
}
</script>

<style lang="sass" scoped>
.balance
  padding: 0 10px
  display: block
  font-size: 15px
  >*:first-child
    margin-bottom: 5px

</style>