<template>
  <div class="map">
    <div class="map__title">Url карты сайта</div>
    <input type="text" class="map__input" 
      :class="{'map__input_green': project.sitemap == sitemap}"
      v-model="sitemap" @keyup.enter="save">
    <div class="map__pagecount">Найдено страниц: {{project.pages[0].length}}</div>
    <div class="map__buttons">
      <Btn :small="true" v-if="project.sitemap != sitemap" @click="save">Сохранить</Btn>
      <Btn :small="true" v-if="project.pages[0].length" @click="downloadPages">Скачать страницы</Btn>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex' 
  import Btn from '../buttons/Btn.vue'
  export default {
    data:()=>({
      sitemap: ''
    }),
    components: { Btn },
    methods: {
      save() {
        this.$emit('edit', { sitemap:this.sitemap })
      },
      syncMap(){
        this.sitemap = this.project.sitemap
      },
      downloadPages(){
        function download(filename, text) {
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
          element.setAttribute('download', filename);

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        }
        download(
          this.project.sitemap.replace(/https?:\/\//,'').replace('.xml','') + '.txt',
          this.project.pages[0].join('\n'))
      }
    },
    mounted(){
      this.syncMap()
    },
    computed: {
      ...mapState(['project'])
    },
    watch:{
      'project.sitemap':function(){
        this.syncMap()
      }
    }
  }
</script>

<style lang="sass" scoped>
.map
  &__title
    font-weight: 700
    margin-bottom: 5px
  &__input
    transition: .3s ease-out
    outline: none
    padding: 5px 14px
    width: 200px
    min-width: 100%
    font-weight: 500
    border: 1px solid rgba(0,0,0,.1)
    border-radius: 7px
    &:focus-visible
      border-color: rgba(0,0,0,.3)
    &_green
      background: rgba(230, 255, 230, 1)
  &__pagecount
    font-weight: 700
    font-size: 12px
    text-align: right
    padding: 3px 0
    margin-bottom: 5px
  &__buttons
    display: flex
    gap: 15px
</style>