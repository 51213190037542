<template>
  <div class="panel">
    <FolderSelector />
    <FolderBtn mode="create"/>
    <FolderBtn mode="edit" v-if="folder" />
    <FolderBtn mode="delete" v-if="folder"/>
    <ProjectSelector class="panel__gap" v-if="folder" />
    <ProjectBtn mode="create" v-if="folder"/>
    <ProjectBtn mode="multicreate" v-if="folder"/>
    <ProjectBtn v-if="project" mode="delete"/>
    <LogoutBtn />
    <Balance class="panel__right" v-if="Role == 2"/>
    <!--
    <AddProjectBtn />
    <DeleteProjectBtn v-if="Project" />
    <AdminBtn v-if="Role == 2"/>
    <Limit  class="panel__right" v-else/>
    -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Balance from './Balance.vue';
import FolderBtn from './buttons/FolderBtn.vue';
import LogoutBtn from './buttons/logoutBtn.vue';
import ProjectBtn from './buttons/ProjectBtn.vue';
import FolderSelector from './FolderSelector.vue';
import ProjectSelector from './ProjectSelector.vue';
  export default {
    computed: {
      ...mapState(["Role", "folder", "project"])
    },
    components: { LogoutBtn, Balance, FolderSelector, FolderBtn, ProjectSelector, ProjectBtn }
}
</script>

<style lang="sass">
.panel
  display: flex
  gap: 10px
  &__right
    margin-left: auto
  &__gap
    margin-left: 20px
  select
    margin-right: 10px
  button
    font-weight: 700
    width: 40px
    height: 40px
    max-height: 40px
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    line-height: 0
</style>