<template>
  <div class="main">
    <TopPanel/>
    <div class="main__content">
      <ProjectSettings class="main__settings" v-if="project"/>
      <ProjectData class="main__data" v-if="project"/>
      <FolderData class="main__data main__data_full" v-if="folder && !project"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProjectData from '../components/ProjectData.vue';
import ProjectSettings from '../components/ProjectSettings.vue';
import TopPanel from '../components/TopPanel.vue';
import FolderData from '../components/ProjData/FolderData.vue';

export default {
  name: 'Main',
  components: { TopPanel, ProjectSettings, ProjectData, FolderData },
  mounted(){
    this.getFoldersIfNeeded()
  },
  methods: {
    getFoldersIfNeeded(){
      if (this.Role) 
        this.$store.dispatch('updateFoldersData')
    }
  },
  computed: {
    ...mapState(['Role', 'project','folder'])
  },
  watch: {
    Role(){
      this.getFoldersIfNeeded()
    }
  }
}
</script>
<style lang="sass">
.main
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black

  padding: 5px
  position: relative
  min-height: 100vh
  background: url('~@/assets/bg.webp')
  background-repeat: repeat
  display: flex
  flex-direction: column
  &::before
    content: ''
    position: absolute
    left:  0
    top: 0
    width: 100%
    height: 100%
    background: rgba(255,255,255,.5)   
    backdrop-filter: blur(2px)
  *
    position: relative
  &__content
    margin-top: 20px
    display: flex
    flex-grow: 1
    position: relative
  &__list
    min-width: 200px
    max-width: 300px
    margin-right: 30px
  &__viewer
    flex-grow: 1
  &__data
    width: calc(100% - 310px)
    margin-left: 20px
    &_full
      width: 100%
      margin-left: 0
</style>