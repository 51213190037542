<template>
  <Teleport to="body">
    <Transition name="fade" >
      <div class="addpopup" @click="$emit('update:modelValue', false)" v-show="modelValue">
        <div class="addpopup__content" @click.stop>
          <div class="addpopup__linklist">
            <a v-for="(ir,i) in list" :key="irnames[i]" @click.prevent="downloadIR(ir)">{{irnames[i]}}</a>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { mapState } from 'vuex'

  export default {
    data: ()=>({
      list: []
    }),
    props: {
      modelValue: {type: Boolean, default: false},
    },
    mounted(){
      this.updateList()
    },
    unmounted(){
      this.list = []
      this.$store.commit('setAllIndexData', [])
    },
    methods: {
      getIrName(ir) {
        return ir.url + ' - ' +  ir.type + ' - ' + ir.date + ' - ' + ir.pagecount + '.txt'
      },
      async downloadIR(ir) {
        let url = encodeURIComponent(this.project.url),
            folder = encodeURIComponent(this.folder.name),
            type = encodeURIComponent(ir.type),
            date = encodeURIComponent(ir.date)
        let filename
        this.$store.commit('toggleLoad','downloadIR')
        await fetch(this.url + `ireport?url=${url}&folder=${folder}&type=${type}&date=${date}`, {
          credentials: 'include',
        })
          .then(async res=>{
            if (!res.ok) throw (await res.text())
            const header = res.headers.get('Content-Disposition')
            const parts = header.split(';')
            filename = parts[1].split('=')[1].replaceAll("\"", "")
            return res.blob()
          }).then(blob=>{
            if (blob != null) {
              var url = window.URL.createObjectURL(blob)
              var a = document.createElement('a')
              a.href = url
              a.download = filename
              document.body.appendChild(a)
              a.click()
              a.remove()
            }
          })
          .catch(err=>{console.error(err); alert(err)})
        this.$store.commit('toggleLoad','downloadIR')
      },
      async updateList(){
        let url = encodeURIComponent(this.project.url),
        folder = encodeURIComponent(this.folder.name)
        this.$store.commit('toggleLoad','IReportlist')
        await fetch(this.url + `ireportlist?url=${url}&folder=${folder}`, {
          credentials: 'include',
        })
          .then(async res=> {
            if (!res.ok) throw(await res.text())
            return res.json()
          })
          .then(async list=>{ 
            list.sort((a,b)=>{
              let d1 = a.date.split('/'), d2 = b.date.split('/')
              d1 = parseInt(d1[0]) + parseInt(d1[1])*32 + parseInt(d1[2]) * 400
              d2 = parseInt(d2[0]) + parseInt(d2[1])*32 + parseInt(d2[2]) * 400
              return d1 - d2
            })
            this.list = list;
            this.$store.commit('setAllIndexData', this.list.map(el=>({type: el.type, date:el.date, count: el.pagecount})))
          } )
          .catch(err=>{console.error(err); alert(err)})
        
        this.$store.commit('toggleLoad','IReportlist')
      }
    },
    computed: {
      ...mapState(['url','folder', 'project']),
      irnames(){
        return this.list.map(el=> this.getIrName(el))
      }
    },
    watch: {
      project(v){
        if (v)
          this.updateList()
        else {
          this.list = []
          this.$store.commit('setAllIndexData', [])
        }
      }
    }
  }
</script>

<style lang="sass">
.addpopup
  &__linklist
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 8px
    a
      font-size: 14px
      color: colors.$blue, 20
      text-decoration: underline
      cursor: pointer
      &:hover, &:focus
        color: darken(colors.$blue, 20)
</style>