<template>
  <div class="pdata">
    <ProjectCharts class="pdata__charts"/>
    <Table v-if="!showHistory">
      <Btn :small="true" color="yellow" @click="(showIReports = true)">Просмотреть файлы</Btn>
      <Btn :small="true" color="blue" @click="showHistory = true">Разбивка по вложенностям</Btn>
    </Table>
    <HistoryTable v-if="showHistory">
      <Btn :small="true" color="blue" @click="showHistory = false" v-if="showHistory">Назад</Btn>
    </HistoryTable>
    <ReportControls />
    <IReportsList v-model="showIReports" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Btn from './buttons/Btn.vue';
import IReportsList from './ProjData/IReportsList.vue';
import ProjectCharts from './ProjData/ProjectCharts.vue'
import ReportControls from './ProjData/ReportControls.vue';
import Table from './ProjData/Table.vue';
import HistoryTable from './ProjData/HistoryTable.vue';
export default {
  components: { ProjectCharts, ReportControls, Table, Btn, IReportsList, HistoryTable },
  data: ()=>({
    showIReports: false,
    showHistory: false
  }),
  computed: {
    ...mapState(['project']),
  }
}
</script>

<style lang="sass" scoped>
.pdata
  &__charts
    display: flex
    gap: 20px
    margin-bottom: 30px
</style>