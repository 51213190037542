<template>
  <div>
    <apexchart width="450" :options="pageOptions" :series="pageSeries"></apexchart>
    <apexchart width="450" :options="mapOptions" :series="mapSeries"></apexchart>
    <apexchart v-if="allIndexData && allIndexData.length" width="450" :options="allIndexDataOptions" :series="allIndexDataSeries"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import { mapState } from 'vuex';
export default {
  components: { apexchart: VueApexCharts },
  data: ()=>({
    stdoptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {
          categories: []
        }
      }
  }),
  computed: {
    ...mapState(['project','allIndexData']),
    mapSeries(){
      return [{
        name: "Страниц",
        data: Object.values(this.project.smpcounts)
      }]
    },
    mapOptions(){
      let res = JSON.parse(JSON.stringify(this.stdoptions))
      res.title.text = 'Число страниц в карте сайта'
      res.xaxis.categories = Object.keys(this.project.smpcounts).map(el=>el.replace(/\/\d+$/,''))
      return res
    },
    repdates(){
      let dates = new Set()
      for (let report of this.project.reports)  
        dates.add(report.date)
      
      return [...dates]
    },
    reptypes(){
      let types = new Set()
      for (let report of this.project.reports)  
        types.add(report.type)
      types = [...types]
      types.sort((a,b)=>b.localeCompare(a))
      return types
    },
    indexdates(){
      let dates = new Set()
      for (let el of this.allIndexData)  
        dates.add(el.date)
      
      return [...dates]
    },
    indextypes(){
      let types = new Set()
      for (let el of this.allIndexData)  
        types.add(el.type)
      types = [...types]
      types.sort((a,b)=>b.localeCompare(a))
      return types
    },
    pageSeries(){
      let res = [], types = this.reptypes, dates = this.repdates
      let ddata = {}
      for (let date of dates) ddata[date] = 0
      for (let type of types) {
        let filtred = this.project.reports.filter(el=>el.type == type)
        let obj = {
          name: type,
          data: []
        }
        for (let date of dates) {
          let r = filtred.find(el=>el.date == date)
          if (!r) {
            obj.data.push(null)
            continue
          }
          let count = 0
          for (let page in r.pages) count += r.pages[page]?1:0
          obj.data.push(count)
          ddata[date] = Math.max(Object.keys(r.pages).length,ddata[date])
        }
        res.push(obj)
      }
      let ddarray = []
      for (let date of dates) 
        ddarray . push(ddata[date])
      res.push({
        name: 'Всего страниц',
        data: ddarray
      })
      
      return res
    },
    pageOptions(){
      let res = JSON.parse(JSON.stringify(this.stdoptions))
      let colors = [], types = this.reptypes
      if (types.includes('yandex')) colors.push('#FFC107')
      if (types.includes('google')) colors.push('#4680FF')
      colors.push('#198754')
      res.colors = colors
      res.title.text = 'Найденные страницы'
      res.xaxis.categories = this.repdates.map(el=>el.replace(/\/\d+$/,''))
      return res
    },
    allIndexDataOptions(){
      let res = JSON.parse(JSON.stringify(this.stdoptions))
      let colors = [], types = this.indextypes
      if (types.includes('yandex')) colors.push('#FFC107')
      if (types.includes('google')) colors.push('#4680FF')
      res.colors = colors
      res.title.text = 'Все проиндексированные страницы'
      res.xaxis.categories = this.indexdates.map(el=>el.replace(/\/\d+$/,''))
      return res
    },
    allIndexDataSeries(){
      let res = [], types = this.indextypes
      for (let type of types)
        res.push({
          name: type,
          data: this.allIndexData.filter(el=>el.type == type).map(el=>el.count)
        })
      return res
    },
  }
}
</script>

<style lang="sass" scoped>
</style>