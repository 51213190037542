<template>
  <div class="psettings">
    <Pagemode @edit="editProject" />
    <PagesInput @edit="editProject" v-if="project.pagemode"/>
    <MapInput @edit="editProject" v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MapInput from './ProjSettingsComps/MapInput.vue'
import Pagemode from './ProjSettingsComps/Pagemode.vue'
import PagesInput from './ProjSettingsComps/PagesInput.vue';
export default {
  methods: {
    async editProject(data) {
      this.$store.commit("toggleLoad", "projectSettings")
      await fetch(this.url + "project", {
        method: "PUT",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          obj: {
            ...data,
            url: this.project.url
          },
          folder: this.folder.name
        })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        await this.$store.dispatch('getProject', this.project.url)
      }).catch(err => {
        console.error(err)
        alert(err)
      })
      this.$store.commit("toggleLoad", "projectSettings")
    }
  },
  computed: {
    ...mapState(["url", "project", "folder"])
  },
  components: {
    Pagemode,
    MapInput,
    PagesInput
  }
}
</script>

<style lang="sass" scoped>
.psettings
  width: 290px
</style>