<template>
  <div class="pagemode">
    <div class="pagemode__title">Брать страницы</div>
    <div class="pagemode__inputs">
      <label>
        <input type="radio" name="pagemode" v-model="pagemode" value="false"/>
        <span>Из карты сайта</span>
      </label>
      <label>
        <input type="radio" name="pagemode" v-model="pagemode" value="true"/>
        <span>Из списка страниц</span>
      </label>      
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['project']),
    pagemode: {
      get(){
        return this.project?.pagemode || false
      },
      set(v){
        this.$emit('edit', { pagemode: v })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.pagemode
  margin-bottom: 10px
  &__title
    font-weight: 700
    margin-bottom: 5px
  &__inputs
    gap: 20px
    &, label
      font-size: 14px
      display: flex
      align-items: center
    input
      margin-right: 3px

</style>