<template>
  <div class="tablezone">
    <div class="uptable-controls tablezone__controls">
      <slot />
      <Btn :small="true" v-if="(reports && daycount)" class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
      <Btn :small="true" v-if="reports && daycount && (pagescount > 100)" @click.prevent.stop="toggleLimit" class="tablesave btn btn_blue">{{ limit ? 'Показать все' : 'Показать 100' }}</Btn>
    </div>
    <div v-if="(reports && daycount)" class="modeswitcher">
      <div class="modeswitcher__title">Данные</div>
      <label v-if="yrcount"><input v-model="mode" type="radio" name="tablemode" value="yandex">Яндекс</label>
      <label v-if="grcount"><input v-model="mode" type="radio" name="tablemode" value="google">Google</label>
      <label v-if="(grcount && yrcount)"><input v-model="mode" type="radio" name="tablemode" value="both">Оба</label>
    </div>
    <div v-if="(reports && daycount && projectpages.length)" class="modeswitcher">
      <div class="modeswitcher__title">Только текущие страницы</div>
      <label><input v-model="filterstring" type="radio" name="filterenabled" value="true">Да</label>
      <label><input v-model="filterstring" type="radio" name="filterenabled" value="false">Нет</label>
    </div>
    <div v-if="(reports && daycount)" class="modeswitcher">
      <div class="modeswitcher__title">Показывать...</div>
      <label><input v-model="collimit" type="radio" name="filterenabled" value="0">Всё</label>
      <label><input v-model="collimit" type="radio" name="filterenabled" value="5">Последние 5 отчётов</label>
      <label><input v-model="collimit" type="radio" name="filterenabled" value="10">Последние 10 отчётов</label>
      <label><input v-model="collimit" type="radio" name="filterenabled" value="15">Последние 15 отчётов</label>
    </div>
    <div style="overflow: auto; width: 100%;">
      <table :data-cols-width="getColWidth" v-if="(reports && daycount)" class="table" ref="table" :class="{ ['table_' + mode]: mode }">
        <tr>
          <td data-a-h="center" data-a-v="middle"></td>
          <td data-a-h="center" data-a-v="middle" v-for="date in dates" :key="date">{{ date.split('/')[0] + '.' + date.split('/')[1] }}</td>
        </tr>
        <tr v-for="page in pages(limit)" :key="page">
          <td data-a-v="middle" class="link" v-html="page"></td>
          <td data-a-h="center" data-a-v="middle" v-for="(day, i) in reports" :key="i + '-' + day.date" :data-fill-color="getXLColor(day.pages[page])" :class="getColorClass(day.pages[page])" :title="getTitle(day.pages[page])">{{ getSign(day.pages[page]) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import TableToExcel from "@linways/table-to-excel"
import { mapState } from "vuex";
import Btn from '../buttons/Btn.vue';

export default {
  components: {
    Btn
  },
  data: () => ({
    pagescount: 0,
    limit: 100,
    collimit: 0,
    mode: "yandex",
    filterstring: "false"
  }),
  computed: {
    ...mapState(['project']),
    projectpages() {
      return this.$store.getters.pages
    },
    allpages() {
      let pages = new Set();
      for (let report of this.reports)
        for (let page in report.pages)
          pages.add(page);

      let res = [...pages]
      if (this.filterenabled)
        res = res.filter(page => this.projectpages.includes(page))
      return res
    },
    bothreports() {
      let res = [], n = 0, rlength = this.project.reports.length
      for (let i = 0; i < this.dates.length; i++) {
        let date = this.dates[i]
        while (n < rlength && (this.project.reports[n].date != date)) n++
        if (n >= rlength) break

        let tomerge = [this.project.reports[n]]
        if (this.project.reports[n + 1] && (this.project.reports[n + 1].date == date))
          tomerge.push(this.project.reports[n + 1])
        res.push(this.mergeReports(tomerge))
      }
      return res
    },
    reports() {
      let res
      if (this.mode !== 'both')
        res = this.project.reports.filter(el => el.type == this.mode)
      else
        res = this.bothreports
      let collimit = parseInt(this.collimit)
      if (!collimit) return res
      return res.slice(-collimit)

    },
    yrcount() {
      return this.project.reports.filter(el => el.type == 'yandex').length
    },
    grcount() {
      return this.project.reports.filter(el => el.type == 'google').length
    },
    curcount() {
      if (this.mode == 'yandex') return this.yrcount
      if (this.mode == 'google') return this.grcount
      if (this.mode == 'both') return Math.min(this.yrcount, this.grcount)
      return 0
    },
    dates() {
      let res = this.reports.map(el => el.date)
      let collimit = parseInt(this.collimit)
      if (!collimit) return res
      return res.slice(-collimit)
    },
    daycount() {
      return this.dates.length
    },
    filterenabled() {
      if (this.filterstring == "true")
        return true;
      return false;
    },
    getColWidth() {
      let str = "40" + this.dates.map(() => ",10").join("")
      return str;
    },
  },
  mounted() {
    this.fixMode()
  },
  methods: {
    fixMode() {
      if (!this.project || (this.curcount != 0)) return
      if (this.yrcount) return this.mode = 'yandex'
      if (this.grcount) this.mode = 'google'
    },
    mergeReports(reports) {
      if (reports.length == 0) return alert('Ошибка: mergeReports - попытка слияния нуля отчётов')
      if (reports.length > 2) return alert('Ошибка: mergeReports - попытка слияния более чем двух отчётов')
      let types = reports.map(el => el.type)
      if (types.length == 1 && reports.length > 1) return alert('Ошибка: mergeReports - типы отчётов идентичны')
      if (reports.length == 1) {
        let r = reports[0]
        let number = (r.type == 'yandex') ? 0 : 1
        let pages = JSON.parse(JSON.stringify(r.pages))
        for (let p in pages) {
          let v = [undefined, undefined]
          v[number] = pages[p]
          pages[p] = this.getBothCellValue(...v)
        }

        return { ...r, pages, type: 'both' }
      }

      let yr = reports.find(el => el.type == 'yandex'),
        gr = reports.find(el => el.type == 'google')

      let ypages = Object.keys(yr.pages),
        gpages = Object.keys(gr.pages),
        ap = [...new Set([...ypages, ...gpages])],
        pages = {}


      for (let p of ap)
        pages[p] = this.getBothCellValue(yr.pages[p], gr.pages[p])

      return { ...yr, pages, type: 'both' }
    },
    getTitle(val) {
      if (typeof (val) !== 'string') return ''
      return 'Найдено частично совпадающее значение: ' + val
    },
    toggleLimit() {
      if (this.limit)
        this.limit = 0;
      else
        this.limit = 100;
    },
    getXLColor(v) {
      switch (v) {
        case "Y":
          return "FFFFC107";
        case "Y?":
          return "FFAD8305";
        case "G":
          return "FF4680FF";
        case "G?":
          return "FF2D54A8";
        case true:
          return "FF64C864";
        case false:
          return "0ADC3545";
        case undefined:
          return "FF555555";
        case null:
          return "FF333333";
        default:
          return 'FF00ffff'
      }
    },
    getBothCellValue(yval, gval) {
      if (yval === true && gval === true) return true
      if (yval === gval) return yval
      if (!yval && !gval) return false
      return [yval, gval]
    },
    save() {
      TableToExcel.convert(this.$refs.table, {
        name: "table.xlsx",
        sheet: {
          name: "Данные"
        }
      });
    },
    getSign(val) {
      if (val === true)
        return "+";
      if (val === false)
        return "";
      if (typeof (val) == 'string')
        return "+?";
      if (Array.isArray(val)) {
        if (val[0] === true && !val[1]) return 'Y'
        if (val[1] === true && !val[0]) return 'G'
        if ((typeof (val[0]) == 'string') && !val[1]) return 'Y?'
        if ((typeof (val[1]) == 'string') && !val[0]) return 'G?'
      }
      return "?"
    },
    getColorClass(val) {
      if (val === true) return 'found'
      if (val === false) return 'notfound'
      if (val === null) return 'broken'
      if (val === undefined) return 'unknown'
      if (typeof (val) == 'string') return this.mode + ' ' + 'halffound'
      if (Array.isArray(val)) {
        if (val[0] === true && !val[1]) return 'yandex'
        if (val[1] === true && !val[0]) return 'google'
        if ((typeof (val[0]) == 'string') && !val[1]) return 'yandex halffound'
        if ((typeof (val[1]) == 'string') && !val[0]) return 'google halffound'
      }
      return 'unknown'
    },
    pages(limit) {
      let res = this.allpages.sort((a,b)=>a.localeCompare(b))
      this.pagescount = res.length;
      if (!limit)
        return res;
      else
        return res.slice(0, limit);
    }
  },
  watch: {
    project() {
      this.fixMode()
    }
  }
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$yandex: #ffc107
$ydark: #ad8305
$google: #4680FF
$gdark: #2d54a8
$success: #198754
$dgreen: darken(#198754, .2)
$primary: #0d6efd
.modeswitcher
  margin-bottom: 10px
  &__title
    font-size: 18px
    margin-bottom: 5px
  &, label
    display: flex
    align-items: center
  gap: 10px
  input
    display: block
    margin-right: 5px

.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
      margin-bottom: 20px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  margin-bottom: 30px

  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  tr
    font-weight: 500
    white-space: nowrap
  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      &:hover 
        overflow: visible
        padding: 0
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.6)
    &.found
      background: rgba(100, 200, 100, .4)
      &:hover
        background: rgba($success, .2)
      &.halffound
        background: $dgreen
        &:hover
          background: lighten($dgreen, .40)
    &.yandex
      background: rgba($yandex, .3)
      &:hover
        background: rgba($yandex, .15)
      &.halffound
        backgound: $ydark
        &:hover
          background: lighten($ydark, .40)
    &.google
      background: rgba($google, .3)
      &:hover
        background: rgba($google, .15)
      &.halffound
        backgound: $gdark
        &:hover
          background: lighten($gdark, .40)        
    &.mention
      background: rgba($yandex, .18)
      &:hover
        background: rgba($yandex, .1)
    &.notfound
      background: rgba($danger, .2)
      &:hover
        background: rgba($danger, .1)
    &.broken
      background: rgba(black, .8)
      &:hover
        background: rgba(black, .6)
    &.unknown
      background: rgba(black, .2)
      &:hover
        background: rgba(black, .1)

</style>