<template>
  <div class="rc">
    <div class="rc__title">Создать отчёт</div>
    <div class="rc__type" id="rc__type">Тип отчёта</div>
    <div class="rc__flex">
      <label>
        <input type="checkbox" v-model="types.yandex" >
        <span>Яндекс</span>
      </label>
      <label>
        <input type="checkbox" v-model="types.google" >
        <span>Google</span>
      </label>
    </div>
    <div class="rc__flex">
      <label>
        <input type="checkbox" v-model="accelerated" >
        <span>Ускоренный сбор</span>
      </label>
    </div>
    <div class="rc__flex">
      <Btn class="rc__btn" @click="createReport">Создать</Btn>
      <Btn class="rc__btn" color="yellow" @click="checkAllLinks">Найти все страницы</Btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Btn from '../buttons/Btn.vue';

export default {
  data: () => ({
    types: {
      yandex: false,
      google: false
    },
    accelerated: false
  }),
  methods: {
    async createReport() {
        let accelerated = this.accelerated
        let types = this.clearTypes
        if (!types.length) return alert('Укажите тип отчёта')
        let url = this.project.url
        let folder = this.folder.name

        this.$store.commit("toggleLoad", "ReportControls")
        await fetch(this.url + "report", {
            method: "POST",
            credentials: 'include',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ types, accelerated, folder, url })
        }).then(async (res) => {
            if (!res.ok)
                throw (await res.text());
            alert(await res.text())
            this.$store.dispatch('getProject', this.project.url)
        }).catch(err => { console.error(err); alert(err); })
        this.$store.commit("toggleLoad", "ReportControls")
    },
    async checkAllLinks() {
      let folder = this.folder.name, url = this.project.url
      let types = this.clearTypes
      if (!types.length) return alert('Укажите тип отчёта')
      let c = confirm(`Будет потрачено ${types.length > 1?'по ':''}` + this.project.pages[0].length + ' запросов для ' + types.join(' и ') + '\nПродолжить?')
      if (!c) return
      this.$store.commit("toggleLoad", "ReportControls")
        await fetch(this.url + "ireport", {
            method: "POST",
            credentials: 'include',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ types, folder, url })
        }).then(async (res) => {
            if (!res.ok) throw (await res.text())
            alert(await res.text())
            this.$store.dispatch('getProject', this.project.url)
        }).catch(err => { console.error(err); alert(err); })
        this.$store.commit("toggleLoad", "ReportControls")
    }
  },
  computed: {
    ...mapState(["url", "project", "folder"]),
    clearTypes(){
      let types = []
      if (this.types.yandex) types.push('yandex')
      if (this.types.google) types.push('google')
      return types
    }
  },
  components: { Btn }
}
</script>

<style lang="sass" scoped>
.rc
  &__title
    font-weight: 700
    font-size: 18px
    margin-bottom: 8px
  &__type
    font-weight: 500
    font-size: 16px
    margin-bottom: 4px
  &__flex
    font-size: 16px
    margin-bottom: 10px
    gap: 16px
    input
      display: block
      margin-right: 4px
    &, label
      display: flex
      align-items: center
  &__btn
    margin-top: 20px
</style>