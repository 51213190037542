<template>
  <div class="pages">
    <div class="pages__title">Список страниц</div>
    <textarea type="text" class="pages__input" 
      :class="{'pages__input_green': pagesInSync}"
      v-model="locpages" />
    <Btn :small="true" v-if="!pagesInSync" @click="save">Сохранить</Btn>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex' 
  import Btn from '../buttons/Btn.vue'
  export default {
    data:()=>({
      locpages: ''
    }),
    components: { Btn },
    methods: {
      save() {
        let p = [this.project.pages[0], this.clearPages]
        this.$emit('edit', { pages: p })
      },
      syncPages(){
        this.locpages = this.project.pages[1].join('\n')
      }
    },
    mounted(){
      this.syncPages()
    },
    computed: {
      ...mapState(['project']),
      ...mapGetters(['pages']),
      clearPages(){
        return this.locpages.split('\n').map(el=>el.trim()).filter(el=>el)
      },
      pagesInSync(){
        if (this.clearPages.length != this.pages.length) return false
        for (let i = 0; i < this.pages.length; i++)
          if (this.clearPages[i] != this.pages[i]) return false
        return true
      }
    },
    watch: {
      project(v){
        if (v.pages)
          this.syncPages()
      }
    }
  }
</script>

<style lang="sass" scoped>
.pages
  &__title
    font-weight: 700
    margin-bottom: 5px
  &__input
    transition: .3s ease-out
    outline: none
    padding: 5px 14px
    width: 200px
    min-width: 100%
    height: 400px
    font-weight: 500
    border: 1px solid rgba(0,0,0,.1)
    border-radius: 7px
    margin-bottom: 5px
    resize: none
    &:focus-visible
      border-color: rgba(0,0,0,.3)
    &_green
      background: rgba(230, 255, 230, 1)
</style>