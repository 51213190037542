<template>
  <div class="tablezone tablezone_folderdata">
    <div class="uptable-controls tablezone__controls">
      <slot />
      <Btn :small="true" v-if="(datecount)" class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
      <Btn :small="true" v-if="(delta)" class="tablesave btn btn_blue" @click="downloadDelta">Скачать дельту</Btn>
    </div>
    <div style="overflow: auto; width: 100%; height: 20px" ref="synccont">
      <div ref="synctable" style="height: 1px"></div>
    </div>
    <div style="overflow: auto; width: 100%; height: calc(100% - 80px);" ref="tablecont">
      <table :data-cols-width="getColWidth" class="table" ref="table">
        <tr class="table__datesticky">
          <td data-a-h="center" data-a-v="middle"></td>
          <td data-a-h="center" data-a-v="middle" v-for="date in folderdates" :key="date"><b>{{ date.split('/')[0] + '.' + date.split('/')[1] }}</b></td>
        </tr>
        <tr v-for="realIndex in indexArray" :key="folder.projects[realIndex]">
          <td data-a-v="middle" class="link" @click="goToProject(folder.projects[realIndex])">{{ folder.projects[realIndex] }}</td>
          <td data-a-h="center" data-a-v="middle" :class="{ [getDiffClass(realIndex, dateindex)]: true, 'selected': chosenProject == folder.projects[realIndex] && dates.includes(date)}" @click="chooseDate(folder.projects[realIndex], date)" v-for="(date, dateindex) in folderdates" :key="date">{{ folder.pcounts[realIndex][date] }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import TableToExcel from "@linways/table-to-excel"
import { mapState, mapGetters } from "vuex";
import Btn from '../buttons/Btn.vue';

export default {
  data: () => ({
    chosenProject: '',
    dates: [],
    interval: null
  }),
  mounted(){
    this.interval = setInterval(()=>{
      this.$refs.synctable.style.setProperty('width', this.$refs.table.offsetWidth + 'px')
    },16)
    this.$refs.synccont.addEventListener('scroll', ()=>{
      this.$refs.tablecont.scrollLeft = this.$refs.synccont.scrollLeft
    })
    this.$refs.tablecont.addEventListener('scroll', ()=>{
      this.$refs.synccont.scrollLeft = this.$refs.tablecont.scrollLeft
    })
  },
  unmounted(){
    clearInterval(this.interval)
  },
  components: {
    Btn
  },
  computed: {
    ...mapState(['folder']),
    ...mapGetters(['folderdates']),
    indexArray(){
      const AR = this.folder.projects
      let indexArray = new Array(AR.length).fill(0).map((el,index)=>index).sort((a, b) => AR[a].localeCompare(AR[b]))
      return indexArray
    },
    delta() {
      return this.chosenProject && this.dates.length
    },
    datecount() {
      return this.folderdates.length
    },
    getColWidth() {
      let str = "40" + this.folderdates.map(() => ",10").join("")
      return str;
    },
  },
  methods: {
    goToProject(project) {
      const option = Array.from(document.body.querySelectorAll('.select_project option')).find(option=>option.value == project)
      const sel = document.querySelector('.select_project')
      if (option) {
        sel.value = project
        sel.dispatchEvent(new Event('change'))
      }
    },
    dateFromString(str) {
      try {
        let dateParts = str.split("/")
        if (dateParts.length != 3) throw ('Неверная длина dateParts')
        return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      } catch (err) {
        console.log('Ошибка dateFromString')
        console.error(err)
        return 0
      }
    },
    chooseDate(project, date) {
      if (this.chosenProject != project) {
        this.dates = []
      }
      this.chosenProject = project
      if (this.dates.length == 0)
        return this.dates = [date]
      if (this.dates.includes(date))
        return this.dates = this.dates.filter(el=>el != date)
      let copydate = this.dateFromString(date)
      if (this.dates.includes(date)) return
      let copy = this.dates.map(el=>this.dateFromString(el))
      copy.push(copydate)
      copy.sort()
      if (copy.length == 3) {
        if (copy[2] != copydate)
          return this.dates[0] = date
        else
          return this.dates[1] = date
      }
      if (copy.length == 2) {
        if (copy[0] == copydate)
          return this.dates = [date, this.dates[0]]
        else 
          return this.dates = [this.dates[0], date]
      }
    },
    getDiffClass(index, dateindex) {
      if (!dateindex) return ''
      const diff = this.folder.pcounts[index][this.folderdates[dateindex]] - this.folder.pcounts[index][this.folderdates[dateindex - 1]]
      if (diff > 0) return 'green'
      if (diff < 0) return 'red'
      return ''
    },
    downloadDelta(){
      this.$store.dispatch('getDeltaData', {project: this.chosenProject, dates: this.dates })
    },
    save() {
      TableToExcel.convert(this.$refs.table, {
        name: "table.xlsx",
        sheet: {
          name: "Данные"
        }
      });
    },
  },
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$yandex: #ffc107
$ydark: #ad8305
$google: #4680FF
$gdark: #2d54a8
$success: #198754
$dgreen: darken(#198754, .2)
$primary: #0d6efd

@keyframes datesticky
  0%
    z-index: 1
    transform: translateX(0px)
  50% 
    z-index: 2
    transform: translateX(0.1px)
  100%
    z-index: 1
    transform: translateX(0px)

.tablezone
  &.tablezone_folderdata
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
.modeswitcher
  margin-bottom: 10px
  &__title
    font-size: 18px
    margin-bottom: 5px
  &, label
    display: flex
    align-items: center
  gap: 10px
  input
    display: block
    margin-right: 5px

.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
      margin-bottom: 20px
.table  
  display: block
  min-width: fit-content
  font-size: 14px
  margin-bottom: 30px
  &__datesticky
    position: sticky
    top: 0px
    z-index: 1
    animation: datesticky 1s linear infinite
    *
      background: white 
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  tr
    font-weight: 500
    white-space: nowrap
  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      &:hover 
        overflow: visible
        padding: 0
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.6)
    &.selected
      border: 2px solid $primary
    &.found, &.green
      background: rgba(100, 200, 100, .4)
      &:hover
        background: rgba($success, .2)
      &.halffound
        background: $dgreen
        &:hover
          background: lighten($dgreen, .40)
    &.yandex
      background: rgba($yandex, .3)
      &:hover
        background: rgba($yandex, .15)
      &.halffound
        backgound: $ydark
        &:hover
          background: lighten($ydark, .40)
    &.google
      background: rgba($google, .3)
      &:hover
        background: rgba($google, .15)
      &.halffound
        backgound: $gdark
        &:hover
          background: lighten($gdark, .40)        
    &.mention
      background: rgba($yandex, .18)
      &:hover
        background: rgba($yandex, .1)
    &.notfound, &.red
      background: rgba($danger, .2)
      &:hover
        background: rgba($danger, .1)
    &.broken
      background: rgba(black, .8)
      &:hover
        background: rgba(black, .6)
    &.unknown
      background: rgba(black, .2)
      &:hover
        background: rgba(black, .1)

</style>