<template>
  <select class="select select_project" v-model="currentProject" >
    <option value=""></option>
    <option v-for="project in projectList" :value="project" :key="project">{{project.replace(/^https?:\/\//,'')}}</option>
  </select>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['url','project']),
    ...mapGetters(['projectList']),
    currentProject: {
      get(){
        return this.project? this.project.url : null
      },
      set(url){
        if (url) {
          this.$store.dispatch('getProject', url)
        } else {
          this.$store.commit('setProject', null)
        }
      }
    }
  }
}
</script>

<style lang="sass">
.select
  min-width: 200px
  font-size: 18px
  padding: 2px 5px
  border-radius: 5px
</style>